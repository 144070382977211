@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.loader {
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Optional: Add these styles to ensure lists show correctly */
.bullet_answer ul {
  list-style-type: disc;
  padding-left: 20px;
}

.bullet_answer li {
  margin-bottom: 5px;
}
@keyframes bouncing-loader {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
}

.underline-animation {
  position: relative;
}

.underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #56f09f; /* Tailwind's purple-600 */
  left: 0;
  bottom: 0;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.underline-animation:hover::after,
.underline-animation.active_link::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeInUp {
  animation: fadeInUp 1s ease-out;
}

@keyframes highlightPulse {
  0% {
    background-color: #4caf50; /* Green */
  }
  50% {
    background-color: #66bb6a; /* Lighter green */
  }
  100% {
    background-color: #4caf50; /* Return to green */
  }
}

.highlight {
  animation: highlightPulse 1.5s infinite ease-in-out;
  border: 1px solid black;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-button {
  background: linear-gradient(45deg, #14f195, #0ac882, #2bd1b5, #0bc383);
  background-size: 400% 400%;
  animation: gradientAnimation 3s ease infinite;
  color: white;
  border-radius: 9999px; /* Full rounded */
  padding: 1rem;
  position: fixed;
  bottom: 20px;
  right: 6px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.gradient-button:hover {
  transform: scale(1.05);
}

/* Custom Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 1s ease forwards;
}

.animate-pulse-fast {
  animation: pulse 3s infinite;
}

.animate-pulse-slow {
  animation: pulse 5s infinite;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.button-gradient {
  position: relative;
  padding: 15px 30px;
  font-size: 18px;
  color: white;
  background: linear-gradient(135deg, #f7971e, #ffd200, #ff306f, #833ab4);
  background-size: 300% 300%;
  border: none;
  border-radius: 50px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.4s ease;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  z-index: 1;
  overflow: hidden;
}

.button-gradient:hover {
  background-position: 100% 0;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4), 0 0 20px rgba(255, 48, 111, 0.8), 0 0 30px rgba(131, 58, 180, 0.8);
  transform: scale(1.1);
}

.button-gradient:before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2), transparent 70%);
  transition: all 0.6s ease;
  z-index: 0;
}

.button-gradient:hover:before {
  top: -30%;
  left: -30%;
  width: 160%;
  height: 160%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.4), transparent 70%);
}


